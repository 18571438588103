.login {
    font-family: Arial, sans-serif;
    background-color: #2a2727;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    max-width: 600px;
    margin: 10px auto;
    border: 1px solid #555;
    box-shadow: 0px 4px 250px 50px #FFBA08;
    border-radius: 10px;
}

.login h3 {
    text-align: center;
    margin-bottom: 10px;
    color: white;
}

.appointmentForm {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.formGroup {
    margin-bottom: 10px;
}

.login label {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
    text-align: left;
    color: white;
}

.inputGroup {
    position: relative;
}

.phoneInput {
    padding-left: 48px;
}

.login input, .phoneInput {
    width: 477px;
    height: 27px;
    padding-right: 12px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    outline: none;
}

.login input {
    padding-left: 10px;
}

.login select {
    width: 477px;
    height: 27px;
    padding-right: 12px;
    padding-left: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    outline: none;
    -webkit-appearance: none;  /* For Chrome, Safari, and Opera */
    -moz-appearance: none;     /* For Firefox */
    appearance: none; 
}

.icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.datePicker {
    width: 477px;
    height: 27px;
    padding-right: 12px;
    padding-left: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    outline: none;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
}

.buttonGroup button {
    flex: 1;
    padding: 5px;
    margin: 0 5px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
}

.buttonGroup button.selected {
    background-color: #007bff;
        color: #fff;
}

.paymentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #040404;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.paymentButton:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.paymentButton:hover:not(:disabled) {
    background-color: #555;
}

.arrowIcon {
    margin-left: 5px;
}

.appointmentType {
    margin-top: 10px;
}
