
.promotion-form {
    background-color: #333;
    color: #fff;
    padding: 30px 40px 30px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 3000px 100px #4e169d;
    max-width: 800px;
    margin: 30px auto;
    border: 1px solid #555;
    text-align: left;

}
.form-gr{
    display: flex;
    gap: 5px;
    align-items: center;

}

.form-gr input, .form-gr select{
    width: 442px;
    height: 27px;
    margin: 5px;
    padding: 0px;
}

.form-group {
    margin-bottom: 20px;
    flex-grow: 1;
    padding-top: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    color: #ccc;
}

.form-group input, 
.form-group textarea {
    border: none;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    margin-top: 0px;
}

.form-group textarea {
    height: 220px;
    width: 581px;
    resize: none;
    position: relative;
}

.date-range {
    display: flex;
    gap: 40px;
    margin-left: 20px;
    flex-wrap: wrap;
}

.date-range input {
    width: 300px;
}

.message-group {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    margin-left: 20px;
}

.send-button {
    display: flex;
    background-color: #d4af37;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 90px;    
    align-items: center;
    gap: 10px;
    font-weight: 700;
}

.send-button:hover {
    background-color: #b8860b;
}

.send-button img{
    width: 25px;
    height: 25px;

}

.appointments-list {
    color: #000;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    position: fixed;
    z-index: 1000;
    width: 442px;
    top: 223px;
    left:43%;
}

.appointment-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.appointment-item:last-child {
    border-bottom: none;
}
.loading-screen, .send-result {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    width: 500px;
    height: 180px;
    text-align: center;
}

.send-result {
    text-align: center;
}
.notificationContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 height: 100%;
 width: 100%;
 background: #0000008f;
 z-index: 4999;
}