.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
}

.modal-content h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
}

.modal-content label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}

.modal-content input,
.modal-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.modal-buttons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.modal-buttons button:hover {
    opacity: 0.8;
}

.modal-buttons button:first-of-type {
    background: #ccc;
    color: #333;
}

.modal-buttons button:last-of-type {
    background: #007bff;
    color: #fff;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.success-message {
    text-align: center;
    font-size: 18px;
    color: #28a745;
    margin-bottom: 20px;
}

.success-button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #28a745;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.success-button:hover {
    background: #218838;
}
