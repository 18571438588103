.sidebar {
    width: 230px;
    background-color: #FFFCF5;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 4px 4px 0px #000000BF;
}

.menu {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.menu-item {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
    margin: 1px;
    padding: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 4px 0px #00000040;
    text-decoration: none; /* Remove underline from links */
}

.menu-item:hover {
    background-color: #947FB3;
    color: #ecf0f1;
    border-radius: 5px;
}

.menu-item.active {
    background-color: #947FB3;
    color: #ecf0f1;
    border-radius: 5px;
}

.menu-icon {
    margin-right: 10px;
}

.sidebar_footer {
    text-decoration: none; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #FFFCF5;
}

.sidebar-change-password-button {
    background-color: #FFFCF5;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

