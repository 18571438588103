.payment-form-container {
    background-color: #333;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 250px 50px #AB0000;
    max-width: 750px;
    margin: 30px auto;
    border: 1px solid #555;
    text-align: left;

}

.payment-form-group {
    display: flex;
    gap: 20px;
    align-items: center;
}

.payment-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    color: #ccc;
}

.label-id{
    margin-right: 32px;
    
}

.payment-form-group input, .payment-form-group select {
    width: 382px;
    height: 27px;
    margin: 5px;
    border-radius: 4px;
    padding: 0px;
}

.payment-result-container {
    overflow-y: auto;
    border-radius: 10px;
    margin-top: 30px;
    width: 100%;
    height: 380px;
    resize: none;
    background: #D9D9D9;
    scrollbar-width: thin; 
    scrollbar-color: teal #f0f0f0; 
}

.payment-card {
    top: 5px;
    background: white;
    color:#333;
    padding: 5px 5px 20px 5px;
    margin: 2px 5px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.payment-date-time {
    position: absolute;
    bottom: 1px;
    right: 15px;
    font-size: 14px;
    color: #676464b9;
}

.no-results {
    color: #080808;
    margin-top: 20px;
}