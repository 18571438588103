.package-form-container {
    background-color: #333;
    color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 250px 50px #35A102;
    max-width: 700px;
    margin: 30px auto;
    border: 1px solid #555;
    text-align: left;
}

.package-form-group {
    align-items: center;
}

.package-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    color: #ccc;
}

.package-price {
    width: 216px;
    padding: 2px;
    margin-bottom: 5px ;
    border-radius: 4px;
}

.package-name {
    width: 442px;
    padding: 2px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.add-button {
    background-color: #d4af37;
    color: #101010;
    border: none;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    align-items: center;
    font-weight: 700;
    margin-top: 10px;
}

.package-display {
    overflow-y: auto;
    background-color: #fff;
    height: 300px;
    color: #000;
    border-radius: 10px;
    margin: 20px 10px 10px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    scrollbar-width: thin; 
    scrollbar-color: teal #f0f0f0; 
}

.package-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.remove-button {
    background-color: #ff6347;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.remove-button:hover {
    background-color: #ff0000;
}
.message{
    margin-top: 5px;
}