@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

* {
    font-family: 'Nunito', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashboard-container {
    display: flex;
    height: 100vh;
    background: #FFFCF5;
}

.main-content {
    flex-grow: 1;
    margin-top: 68px;
    margin-left: 231px;
    padding: 20px;
    overflow-y: auto;
}

.schedule {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5px;
    box-shadow: 0px 4px 250px 50px #FFBA08;
}

.schedule th, .schedule td {
    border: 1px solid #000000;
    padding: 5px;
    text-align: center;
}


.schedule th {
    background-color: #D9D9D9;
}

.schedule tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.schedule tr:nth-child(even) {
    background-color: #D9D9D9;
}

.schedule .reserved {
    background-color: #d0e1ff;
    cursor: pointer; /* Adding pointer cursor for reserved cells */
    transition: background-color 0.3s ease;
}

.schedule .reserved:hover {
    background-color: #a8c5ff; /* Lighter shade on hover */
}

.schedule .reserved:focus,
.schedule .reserved:active {
    outline: 2px solid #007bff; /* Blue outline when cell is clicked or focused */
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding: 0 20px;
}

.nav2-button {
    background: #d0e1ff00;
    color: #000000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}

.nav2-btn-icon {
    color: #FE0505;
    margin-right: 8px;
    margin-left: 8px;
}

.reservation-details {
    display: flex;
    gap: 60px;
    text-align: left;
    justify-content: center;
}

.reservation-card {
    background: linear-gradient(259.28deg, #FFBA08 58.97%, #FFBA08 97.95%);
    width: 50%;
    padding: 20px 40px ;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    
}

.reservation-card p {
    margin: 5px 0;
}

.reservation-card button {
    background: linear-gradient(259.28deg, #FFBA08 58.97%, #FFBA08 97.95%);
    color: rgb(23, 22, 22);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
    font-style: italic;
}
.reservation-card div{
    display: flex;
    justify-content: space-between;
}
.reservation-card img{
    height: 40px;
    width: 40px;
}
.reserv-result {
    width: 30%;
    background: #D9D9D9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position:static;
    right: 10px;
    font-weight: 400;
}

.reserv-result p {
    margin: 5px 0;
}
.cell.blocked {
    background-color: red;
    color: white;
}
.kaka{
    background-color: #FE0505;
}

.schedule td {
    transition: background-color 0.6s ease;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .quill {
    min-height: 300px; /* Ensure a taller editor */
    background-color: #ffffff; /* Word-like white background */
    border-radius: 4px; /* Rounded edges */
    font-family: 'Calibri', sans-serif; /* Calibri for Word-like feel */
    padding: 15px; /* Add padding */
  }
  
 .ql-toolbar {
    position: absolute;
    top: 0;
    z-index: 1000; 
    width: 89.3%;
    background: #fff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .quill-editor .ql-container {
    margin-top: 60px; 
  }
  .ql-container{
    margin-top: 1.5rem;
  }
  
  
  