
  
  .add-note-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .add-note-button:hover {
    background-color: #0056b3;
  }
  
  .notes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .note {
    width: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
  }
  
  .note:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .note h2 {
    margin: 0;
    color: #555;
  }
  
  .note p {
    color: #777;
  }
  
  .note-actions {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .edit-button,
  .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    border-radius: 10px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .save-button,
  .cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
  }

  
  .note .card {
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: transform 0.2s;
  }
  

  
  .position-absolute {
    pointer-events: none;
  }
  