.change-password-container {
    background: #202020;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 250px 50px #028EA1;
    max-width: 450px;
    margin: 30px auto;
}

.change-password-form {
    display: flex;
    flex-direction: column;
    margin: 40px;
}
.cng-pass-header{
    color: #FFFFFF;
}
.password-input {
    background-color: #FFFCF5;
    color: #000000;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.password-input::placeholder {
    color: #947FB3;
}

.password-input:focus {
    outline: none;
    border-color: #947FB3;
}

.change-password-button {
    background-color: #D89C00;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.change-password-button:hover {
    background-color: #c6b71a;
}
.error-message, .success-message{
    color: white;
}